<template>
  <div class="enigma-container">
    <overlay-navigation class="fixed z-40 w-full h-full" :hide-info="true" />
    <enigma-scroll />
  </div>
</template>

<script>
import OverlayNavigation from '@/components/OverlayNavigation.vue'
import EnigmaScroll from '@/components/EnigmaScroll.vue'
export default {
  name: 'Enigma7',
  components: {
    OverlayNavigation,
    EnigmaScroll
  },
  mounted() {
    // Trackings
    this.$gtmTracking.pageView()
    this.$mmTro.sendRtg()
  }
}
</script>

<style lang="scss" scoped>
</style>